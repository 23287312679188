
export default {
  props: {
    author: {
      type: Object,
      required: true
    },
    showVerify: {
      type: Boolean,
      required: true,
      defaults: true
    }
  },
}
